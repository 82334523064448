import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { analyticsStore, turmaAlunoAtividadeStore, } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
let RelatorioFaltas = class RelatorioFaltas extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'aluno',
                text: 'Aluno',
                type: 'str',
                value: 'aluno_nome',
            },
            {
                show: true,
                table: 'professor',
                text: 'Professor',
                type: 'str',
                value: 'full_name',
            },
            {
                show: true,
                table: 'turma',
                text: 'Turma',
                type: 'str',
                value: 'codigo_referencia',
            },
            {
                show: true,
                table: 'turma_aluno_atividade',
                text: 'Data',
                type: '',
                value: 'atividade_data_executada',
            },
            {
                show: true,
                table: 'atividade',
                text: 'Unidade',
                type: 'str',
                value: 'descricao',
            },
            {
                show: true,
                table: 'atividade',
                text: 'Aula',
                type: 'str',
                value: 'ordem',
            },
            {
                show: true,
                table: 'turma_aluno_atividade',
                text: 'Status',
                value: 'atividade_presenca',
            },
        ];
        this.selectPeriodDate = null;
        this.faltaInicial = null;
        this.faltaFinal = null;
        this.isLoading = false;
    }
    get totals() {
        return analyticsStore.totals;
    }
    atividadeStatusOptions(item) {
        if (item.reposicao_concluida === true) {
            return { text: 'R', color: 'blue' };
        }
        if (item.reposicao_nao_repor === true) {
            return { text: 'R', color: '#EB5406' };
        }
        if (item.reposicao_agendada === true) {
            return { text: 'R', color: 'orange' };
        }
        if (item.atividade_presenca === false) {
            return { text: 'F', color: '#EB5406' };
        }
        return { text: 'N/D', color: '' };
    }
    validarFaltaInicial(value) {
        if (value === null || value < 1) {
            return 'O Intervalo inicial deve ser maior ou igual a 1.';
        }
        const parsedFaltaFinal = Number(this.faltaFinal);
        if (!isNaN(parsedFaltaFinal) && value > parsedFaltaFinal) {
            return 'O Intervalo inicial deve ser menor ou igual ao Intervalo final.';
        }
        return true;
    }
    validarFaltaFinal(value) {
        if (value === null || value < 1) {
            return 'O Intervalo final deve ser maior ou igual a 1.';
        }
        const parsedFaltaInicial = Number(this.faltaInicial);
        if (!isNaN(parsedFaltaInicial) && value < parsedFaltaInicial) {
            return 'O Intervalo final deve ser maior ou igual ao Intervalo inicial.';
        }
        return true;
    }
    async buscar() {
        this.isLoading = true;
        const filtros = {
            atividade_data_executada: {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        };
        if (this.faltaInicial !== null && this.faltaFinal !== null) {
            // @ts-ignore
            filtros.faltas_consecutivas = {
                type: 'range',
                value: [this.faltaInicial, this.faltaFinal],
            };
        }
        analyticsStore.setDefaultFilters(filtros);
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
        this.isLoading = false;
    }
    async doSearchItems(queryIn) {
        return await turmaAlunoAtividadeStore.getRelatorioFaltasQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsRelatorioFaltas({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(turmaAlunoAtividadeStore.getRelatorioFaltasQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
    }
};
RelatorioFaltas = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
            OpenNewTabComponent,
        },
    })
], RelatorioFaltas);
export default RelatorioFaltas;
